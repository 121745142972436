import { createContext, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useErrorAlerts } from "../components/Alerts/useErrorAlerts";
import { fetchSession } from '../db';
import { AppRoutes } from './Modal';

interface Props {
    children: JSX.Element;
}

interface AuthContextProps {
    isLoggedIn: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthRequired: React.FC<Props> = ({ children }) => {
    const checkAuth = async () => await fetchSession();
    const [isLoggedIn, setLoggedIn] = useState<boolean>(true);
    const [realTimeLoggedIn, setRealTimeLoggedIn] = useState<boolean>(false);
    const handleError = useErrorAlerts();
    const { pathname } = useLocation();

    useEffect(() => {
        checkAuth()
            .then((res) => (setLoggedIn(!!res.userCtx.name), setRealTimeLoggedIn(!!res.userCtx.name)))
            .catch((e) => handleError(e, 'auth_required'));
    }, [pathname]);

    return isLoggedIn ? (
        <AuthContext.Provider value={{ isLoggedIn: realTimeLoggedIn }}>
            {children}
        </AuthContext.Provider>
    ) : (
        <Navigate to={AppRoutes.LOGIN} replace={true} />
    );
};