import { FC } from 'react';

interface Props {
    className?: string;
    outline?: boolean;
}

export const IconPlus: FC<Props> = ({ className, outline = true }) => {
    return outline ? (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none">
            <path
                opacity="0.5"
                d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                stroke="#F2F2F2"
                strokeWidth="1.5"
            />
            <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#F2F2F2" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    ) : (
        <svg width="20" height="20" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round" className={className}>
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
    );
};
