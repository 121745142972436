import { FC, ReactNode, useEffect, useRef } from 'react';

export enum EVARIANT {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    INFO = 'info',
}

interface IMessageBannerProps {
    children: ReactNode;
    className?: string;
    childClassName?: string;
    variant?: EVARIANT;
    autoScroll?: boolean;
}

export const MessageBanner: FC<IMessageBannerProps> = ({ children, childClassName, autoScroll, className = '', variant = EVARIANT.PRIMARY }) => {
    const banner = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (autoScroll) banner.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [autoScroll]);

    let variantClass = '';
    switch (true) {
        case variant === EVARIANT.SECONDARY:
            variantClass = 'text-secondary bg-secondary-light';
            break;
        case variant === EVARIANT.SUCCESS:
            variantClass = 'text-success bg-success-light';
            break;
        case variant === EVARIANT.WARNING:
            variantClass = 'text-warning bg-warning-light';
            break;
        case variant === EVARIANT.DANGER:
            variantClass = 'text-danger bg-danger-light';
            break;
        case variant === EVARIANT.INFO:
            variantClass = 'text-info bg-info-light';
            break;
        case variant === EVARIANT.PRIMARY:
        default:
            variantClass = 'text-primary bg-primary-light';
    }

    return (
        <div ref={banner} className={`${className} flex items-center p-3.5 rounded ${variantClass}`}>
            <span className={`${childClassName} ltr:pr-2 rtl:pl-2 flex items-center flex-wrap`}>{children}</span>
        </div>
    );
};
