import { useCallback, useState } from 'react';
import { useErrorAlerts } from '../components';
import { IClub } from '../db';
import { getTUMGateways } from '../db/Clients/teamupmate';
import { gatewayDB, IGateway } from '../db/Couch/gateway';

const useGateways = () => {
  const [gateways, setGateways] = useState<Array<IGateway>>([]);
  const handleError = useErrorAlerts();

  const getTSGateways = useCallback((id: string) => {
    gatewayDB<IGateway>().find({
      selector: {
        clubId: id,
      }
    }).then((result) => {
      setGateways(result.docs);
    }).catch((err) => {
      handleError(err, 'Error fetching gateways');
    });
  }, []);

  const _getTUMGateways = useCallback((id: string) => {
    if (!id) return;
    getTUMGateways(id).then((response) => {
      if (response.success) {
        setGateways(response.data.map((gateway) => ({
            _id: gateway.paymentGatewayId,
            name: gateway.name,
            type: gateway.stripeAccountId ? 'STRIPE' : 'OFFLINE',
            stripeAccountId: gateway.stripeAccountId || '',
            stripeConfigured: !!gateway.stripeAccountId,
            clubId: id,
            instructions: gateway.instructions,
            whoPaysTheFee: gateway.whoPaysTheFee,
            autoApprove: !gateway.requireApproval,
          }) as IGateway)
        );
      } else {
        throw new Error(response.message);
      }
    }).catch((err) => {
      handleError(err, 'Error fetching gateways');
    });
  }, []);

  const getGateways = useCallback((selectedClub: IClub) => {
    if (selectedClub?._id) {
      if (selectedClub.tumKey) {
        _getTUMGateways(selectedClub._id);
      } else {
        getTSGateways(selectedClub._id);
      }
    }
  }, [_getTUMGateways, getTSGateways]);

  return { gateways, getGateways };
};

export default useGateways;