import PouchDB from 'pouchdb-browser';
import { DB_URL, GATEWAY_DB, PSWD, USN } from '../../utils/constants';

export interface IGateway {
    _id: string;
    _rev: string;
    name: string;
    type: 'OFFLINE' | 'STRIPE';
    stripeAccountId: string;
    stripeConfigured: boolean;
    clubId: string;
    instructions?: string;
    whoPaysTheFee?: 'CLUB' | 'CUSTOMER' | 'SPLIT';
    autoApprove?: boolean;
}

export interface CreateGateway {
    name: string;
    type: 'OFFLINE' | 'STRIPE';
    stripeAccountId?: string;
    stripeConfigured?: boolean;
    clubId: string;
    instructions?: string;
    whoPaysTheFee?: 'CLUB' | 'CUSTOMER' | 'SPLIT';
    autoApprove?: boolean;
}

export const gatewayDB = <T extends IGateway | CreateGateway>() =>
    new PouchDB<T>(`${DB_URL}/${GATEWAY_DB}`, {
        skip_setup: true,
        auth: { username: USN, password: PSWD },
        fetch: function (url, opts) {
            return PouchDB.fetch(url, opts);
        },
    });
