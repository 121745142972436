import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUserError } from '../../db';
import { AppRoutes } from '../../router';
import { showAlert } from './Popup';

export const useErrorAlerts = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const loginAlert = (e: IUserError) => {
        showAlert({
            icon: 'error',
            titleText: t('PLEASE_LOGIN_TITLE'),
            text: t('PLEASE_LOGIN_BODY'),
            confirmButtonText: t('LOGIN'),
            cb: () => navigate(AppRoutes.LOGIN, { state: { ref: location.pathname + location.search } }),
            footer: '<a href="https://docs.targetscore.com/docs/contact" target="_blank">Do you think it is an issue? Please report.</a>',
        });
    };

    const conflictAlert = () =>
        showAlert({
            icon: 'error',
            titleText: t('ALREADY_EXIST'),
            text: t('ALREADY_EXIST_BODY'),
            confirmButtonText: t('TRY_AGAIN'),
        });

    const errorAlert = (message?: string) =>
        showAlert({
            icon: 'error',
            titleText: t('SOMETHING_WENT_ERROR'),
            text: message || t('SOMETHING_WENT_ERROR_BODY'),
            confirmButtonText: t('TRY_AGAIN'),
        });

    return (e: any, section: string) => {
        if (e.status !== 403) {
            captureException(JSON.stringify(e), {
                tags: {
                    section: section,
                },
            });
        }
        if (e.status === 403 && !e.message?.includes("Operation not allowed")) {
            loginAlert(e);
        } else if (e.status === 409) {
            conflictAlert();
        } else if (e.message) {
            errorAlert(e.message);
        } else {
            errorAlert();
        }
    };
};
