import { HOST } from '../../utils/constants';
import { HEADERS } from './headers';

export interface ServiceResult<T> {
    success: boolean;
    message: string;
    data: T;
    errorCode: number | null;
}

export interface ITaxItem {
    taxId: number;
    taxName: string;
    taxRate: number;
    description: string;
    orgId: string;
}

export interface ITUMGateways {
    paymentGatewayId: string;
    name: string;
    instructions: string;
    requireApproval: boolean;
    metaData: any;
    stripeAccountId: string | null;
    whoPaysTheFee: 'CLUB' | 'CUSTOMER' | 'SPLIT';
}

export interface IOrderItem {
    itemId: string;
    itemName: string;
    price: number;
    taxId: number;
    taxName: string;
    taxRate: number;
    quantity: number;
    orgId: string;
}

export interface IOrderRequest {
    invoiceNumber?: number;
    orderType: string;
    orderDate: string; // ISO date string
    dueDate?: string; // ISO date string
    orderStatus: 'Failed' | 'PaymentPending' | 'Completed' | 'Cancelled';
    userId?: string;
    name: string;
    email?: string;
    phone?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    totalAmount: number;
    netPrice: number;
    shippingPrice?: number;
    discount?: number;
    donation?: number;
    items: string[];
    memberNote?: string;
    privateNote?: string;
    orgId: string;
    paymentMethod?: string;
}

export const getTaxItems = async (clubId: string): Promise<ServiceResult<ITaxItem[]>> => {
    try {
        const response = await fetch(HOST + 'tax-items/' + clubId, {
            ...HEADERS,
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        return response.json();
    } catch (error) {
        console.error('Failed to fetch API keys:', error);
        throw error;
    }
};

export const createOrderItem = async (clubId: string, orderItem: any): Promise<ServiceResult<IOrderItem>> => {
    try {
        const response = await fetch(HOST + 'order-items/' + clubId, {
            ...HEADERS,
            method: 'POST',
            body: JSON.stringify(orderItem),
        });
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        return response.json();
    } catch (error) {
        console.error('Failed to fetch API keys:', error);
        throw error;
    }
};

export const getTUMGateways = async (clubId: string): Promise<ServiceResult<Array<ITUMGateways>>> => {
    try {
        const response = await fetch(HOST + 'tum-gateways/' + clubId, {
            ...HEADERS,
            method: 'GET',
        });
        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        return response.json();
    } catch (error) {
        console.error('Failed to fetch API keys:', error);
        throw error;
    }
};
