import { maskitoNumberOptionsGenerator } from '@maskito/kit';

export const currencyMask = (prefix: string = '$') => maskitoNumberOptionsGenerator({
    decimalZeroPadding: true,
    precision: 2,
    decimalSeparator: '.',
    min: 0,
    prefix,
});

export const scoreMask = maskitoNumberOptionsGenerator({
    decimalZeroPadding: false,
    precision: 3,
    decimalSeparator: '.',
    min: 0,
});