import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SelectedEvent } from '../../components';
import { eventDB, IEvent } from '../../db';
import { IRootState } from '../../store';
import { setEvent } from '../../store/eventReducer';
import CreateEvent from '../CreateEvent';


export const EditEventDetails: FC = () => {
  const { t } = useTranslation();
  const {
    event: { selectedEvent },
  } = useSelector((state: IRootState) => state);
  const [event, setSelectedEvent] = useState<IEvent>();

  useEffect(() => {
    let updated = false;
    if (selectedEvent._id && updated === false) {
      eventDB().get(selectedEvent._id).then((event) => {
        updated = true;
        setSelectedEvent(event);
        setEvent(event);
      });
    }
  }, [selectedEvent._id]);

  return <section>
    <SelectedEvent />
    {Object.keys(selectedEvent).length > 0 && <div className='my-6'>
      <h1 className='text-2xl mb-5 font-semibold text-primary'>{t("EDIT_EVENT")}</h1>
      <CreateEvent event={event} closeModal={() => { }} edit />
    </div>}
  </section>;
}