import { useTranslation } from 'react-i18next';
import { version } from "../../../package.json";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className="text-center sm:text-left p-6 mt-auto flex justify-between">
            <span>2022 - {new Date().toLocaleDateString('en', { year: '2-digit' })} © TargetScore {t('ALL_RIGHTS_RESERVED')}.</span>
            <span className="mr-4 text-gray-600 text-xs">v{version}</span>
        </div>
    );
};

export default Footer;
