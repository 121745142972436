import { HOST } from '../../utils/constants';

export const createCheckoutSession = async (eventId: string, athleteId: string, repersentingCountry: string, donation: string, metadata: any) => {
    return await fetch(HOST + 'checkout-session', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            eventId,
            athleteId,
            repersentingCountry,
            donationAmount: donation,
            metadata,
        }),
    }).then((res) => res.json());
};

export const getSession = async (sessionId: string, accountId: string): Promise<any> => {
    return await fetch(`${HOST}checkout-session?sessionId=${sessionId}&accountId=${accountId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((res) => res.json());
};

export const fetchClientSecret = async (connectedAccountId: string) => {
    const response = await fetch(HOST + 'account_session', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            account: connectedAccountId,
        }),
    });

    if (!response.ok) {
        // Handle errors on the client side here
        const { error } = await response.json();
        throw new Error(`An error occurred: ${error}`);
    } else {
        const { client_secret: clientSecret } = await response.json();
        return clientSecret;
    }
};

export const createPriceItem = async (amount: number, currency: string, name: string, connectedAccountId: string) => {
    return await fetch(HOST + 'price-item', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            amount,
            quantity: 1,
            currency,
            name,
            connectedAccountId,
        }),
    }).then((res) => res.json());
};

export const updatePriceItem = async (priceId: string, status: boolean, name: string, connectedAccountId: string) => {
    return await fetch(HOST + 'price-item', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            priceId,
            active: status,
            name,
            connectedAccountId,
        }),
    }).then((res) => res.json());
};
