const {
    VITE_NODE_ENV: ENV,
    VITE_CLOUDFRONT_URL: CLOUDFRONT_URL,
    VITE_SITEWIDE_NOTICE: SITEWIDE_NOTICE,
    VITE_HOST: HOST,
    VITE_DB_URL: DB_URL,
    VITE_ATHLETE_DB: ATHLETE_DB,
    VITE_GATEWAY_DB: GATEWAY_DB,
    VITE_USN: USN,
    VITE_PSWD: PSWD,
    VITE_CLUB_DB: CLUB_DB,
    VITE_EVENTS_DB: EVENTS_DB,
    VITE_RECORD_DB: RECORD_DB,
    VITE_REQUESTS_DB: REQUESTS_DB,
    VITE_SQUAD_DB: SQUAD_DB,
    VITE_RESULTS_DB: RESULTS_DB,
    VITE_REACT_APP_STRIPE_PUBLISHABLE_KEY: STRIPE_PUBLISHABLE_KEY,
} = import.meta.env;

export { ATHLETE_DB, CLOUDFRONT_URL, CLUB_DB, DB_URL, ENV, EVENTS_DB, GATEWAY_DB, HOST, PSWD, RECORD_DB, REQUESTS_DB, RESULTS_DB, SITEWIDE_NOTICE, SQUAD_DB, STRIPE_PUBLISHABLE_KEY, USN };

