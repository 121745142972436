import { forwardRef } from 'react';

export type RadioProps = {
    id: string;
    name: string;
    label?: string | null;
    message?: string | false;
    error?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    options: Array<string>;
    setFieldValue: Function;
    required: boolean;
};

type Ref = HTMLInputElement;

const RadioComponent = forwardRef<Ref, RadioProps>((props, ref) => {
    const { id, name, label = null, message = false, error = false, fullWidth = false, disabled = false, size = 'medium', className, options, setFieldValue, ...restProps } = props;

    return (
        <fieldset>
            {label != null && <legend className={'mb-2 block text-sm text-gray-800 font-bold'}>{label} {props.required && "*"}</legend>}
            {options?.length > 0 && (
                <div className="flex gap-4 flex-wrap">
                    {options.map((option: string, i: number) => {
                        return (
                            <label key={i} className="inline-flex" htmlFor={option.replaceAll(/[^a-z_]/gi, '-')}>
                                <input
                                    ref={ref}
                                    type="radio"
                                    name={name}
                                    className="form-checkbox text-success rounded-full"
                                    id={option.replaceAll(/[^a-z_]/gi, '-')}
                                    value={option}
                                    onChange={(e) => setFieldValue([name], e.target.value)}
                                />
                                <span>{option}</span>
                            </label>
                        );
                    })}
                </div>
            )}
        </fieldset>
    );
});

RadioComponent.displayName = 'Radio';

export const Radio = RadioComponent;
