import { ERules } from './rules';

export enum ECLASSIFICATION {
    MS = 'MS',
    EX = 'EX',
    SS = 'SS',
    GS = 'GS',
    HM = 'HM',
}

export const NRAAClassification = [
    { code: 'A', name: 'A' },
    { code: 'B', name: 'B' },
    { code: 'C', name: 'C' },
    { code: 'O', name: 'O' },
];

export const NRAUKClassification = [
    { code: 'X', name: 'X' },
    { code: 'A', name: 'A' },
    { code: 'T', name: 'T' },
    { code: 'O', name: 'O' },
];

export const WIFSCClassification = [
    { code: 'X', name: 'X' },
    { code: 'T', name: 'T' },
    { code: 'O', name: 'O' },
];

export const DCRAClassification = [
    { code: 'MS', name: 'Master' },
    { code: 'EX', name: 'Expert' },
    { code: 'SS', name: 'Sharp Shooter' },
    { code: 'GS', name: 'Greenshot' },
];

export const NRAClassification = [
    { code: 'HM', name: 'High Master' },
    { code: 'MS', name: 'Master' },
    { code: 'EX', name: 'Expert' },
    { code: 'SS', name: 'Sharp Shooter' },
    { code: 'M', name: 'Marksman' },
];

export const getClassificationByRules = (rules: string | undefined) => {
    switch (true) {
        case rules === ERules.DCRA:
            return DCRAClassification;
        case rules === ERules.NRAUK:
        case rules === ERules.ICFRA:
            return NRAUKClassification;
        case rules === ERules.NRAA:
            return NRAAClassification;
        case rules === ERules.WIFSC:
            return WIFSCClassification;
        default:
            return NRAClassification;
    }
};
