import { Dialog, Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { eventDB } from '../../db';
import { AppRoutes } from '../../router';
import { IRootState } from '../../store';
import { setEvent } from '../../store/eventReducer';
import { toggleSidebar } from '../../store/themeConfigSlice';
import { caching } from '../../utils/cachingCheck';
import { IconCalendar, IconCaretDown } from '../Icons';
import { EditEvent } from "../Modals/EditEvent";
import { SelectedEvent } from "../Shared/SelectedEvent";

const Sidebar = () => {
    const [currentMenu, setCurrentMenu] = useState<string>('');
    const [eventModal, setEventModal] = useState<boolean>(false);
    const {
        themeConfig,
        user,
        event: { selectedEvent },
        club: { selectedClub },
    } = useSelector((state: IRootState) => state);
    const semidark = useSelector((state: IRootState) => state.themeConfig.semidark);
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { roles = [] } = user.user;
    const isAdmin = roles.includes('admin');
    const isAthlete = roles.includes('athlete');
    const isClub = roles.includes('club');
    const isManager = roles.includes('manager');

    const toggleMenu = (value: string) => {
        if (value === 'event' && Object.keys(selectedEvent).length === 0) {
            setEventModal(true);
        } else {
            setCurrentMenu((oldValue) => {
                return oldValue === value ? '' : value;
            });
        }
    };

    useEffect(() => {
        if (Object.keys(selectedEvent).length) {
            setEventModal(false);
            setCurrentMenu('event');
        }
    }, [selectedEvent]);

    useEffect(() => {
        const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const ul: any = selector.closest('ul.sub-menu');
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link') || [];
                if (ele.length) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            }
        }
    }, []);

    useEffect(() => {
        caching();
    }, [location.pathname]);

    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const fetchEvents = useCallback(() => {
        if (selectedClub) {
            eventDB()
                .get(selectedClub._id)
                .then((response) => setEvent(response));
        }
    }, [selectedClub]);

    return (
        <div className={semidark ? 'dark' : ''}>
            <nav
                className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-40 transition-all duration-300 ${semidark ? 'text-white-dark' : ''}`}
            >
                <div className="bg-white dark:bg-black h-full">
                    <div className="flex justify-between items-center px-4 py-3">
                        <NavLink to={AppRoutes.DASHBOARD} className="main-logo flex shrink-0 items-center">
                            <img
                                className="ml-[5px] flex-none"
                                src={themeConfig.theme === 'dark' ? '/assets/images/target-score-logo-light.png' : '/assets/images/target-score-logo.png'}
                                alt="Target Score - Fullbore Event Management App"
                            />
                        </NavLink>

                        <button
                            type="button"
                            className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
                            onClick={() => dispatch(toggleSidebar())}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 m-auto">
                                <path d="M13 19L7 12L13 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path opacity="0.5" d="M16.9998 19L10.9998 12L16.9998 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <PerfectScrollbar className="h-[calc(100vh-80px)] relative">
                        <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                            <li className="menu nav-item">
                                <NavLink to={AppRoutes.DASHBOARD} className="group">
                                    <div className="flex items-center">
                                        <svg className="shrink-0 group-hover:!text-primary" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                opacity="0.5"
                                                d="M2 12.2039C2 9.91549 2 8.77128 2.5192 7.82274C3.0384 6.87421 3.98695 6.28551 5.88403 5.10813L7.88403 3.86687C9.88939 2.62229 10.8921 2 12 2C13.1079 2 14.1106 2.62229 16.116 3.86687L18.116 5.10812C20.0131 6.28551 20.9616 6.87421 21.4808 7.82274C22 8.77128 22 9.91549 22 12.2039V13.725C22 17.6258 22 19.5763 20.8284 20.7881C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.7881C2 19.5763 2 17.6258 2 13.725V12.2039Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75H15C15.4142 18.75 15.75 18.4142 15.75 18C15.75 17.5858 15.4142 17.25 15 17.25H9Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#cbd5e1] dark:group-hover:text-white-light">{t('DASHBOARD')}</span>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="menu nav-item">
                                <NavLink to={AppRoutes.RESULTS} className="group">
                                    <div className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M11.1459 3.02251C11.5259 2.34084 11.7159 2 12 2C12.2841 2 12.4741 2.34084 12.8541 3.02251L12.9524 3.19887C13.0603 3.39258 13.1143 3.48944 13.1985 3.55334C13.2827 3.61725 13.3875 3.64097 13.5972 3.68841L13.7881 3.73161C14.526 3.89857 14.895 3.98205 14.9828 4.26432C15.0706 4.54659 14.819 4.84072 14.316 5.42898L14.1858 5.58117C14.0429 5.74833 13.9714 5.83191 13.9392 5.93531C13.9071 6.03872 13.9179 6.15023 13.9395 6.37327L13.9592 6.57632C14.0352 7.36118 14.0733 7.75361 13.8435 7.92807C13.6136 8.10252 13.2682 7.94346 12.5773 7.62535L12.3986 7.54305C12.2022 7.45265 12.1041 7.40745 12 7.40745C11.8959 7.40745 11.7978 7.45265 11.6014 7.54305L11.4227 7.62535C10.7318 7.94346 10.3864 8.10252 10.1565 7.92807C9.92674 7.75361 9.96476 7.36118 10.0408 6.57632L10.0605 6.37327C10.0821 6.15023 10.0929 6.03872 10.0608 5.93531C10.0286 5.83191 9.95713 5.74833 9.81418 5.58117L9.68403 5.42898C9.18097 4.84072 8.92945 4.54659 9.01723 4.26432C9.10501 3.98205 9.47396 3.89857 10.2119 3.73161L10.4028 3.68841C10.6125 3.64097 10.7173 3.61725 10.8015 3.55334C10.8857 3.48944 10.9397 3.39258 11.0476 3.19887L11.1459 3.02251Z"
                                                fill="#828282"
                                            />
                                            <path
                                                d="M13 10H11C9.58579 10 8.87868 10 8.43934 10.4393C8 10.8787 8 11.5858 8 13V22H16V19V13C16 11.5858 16 10.8787 15.5607 10.4393C15.1213 10 14.4142 10 13 10Z"
                                                fill="#828282"
                                            />
                                            <g opacity="0.5">
                                                <path
                                                    d="M7.56066 19.4393C7.12132 19 6.41421 19 5 19C3.58579 19 2.87868 19 2.43934 19.4393C2 19.8787 2 20.5858 2 22H8C8 20.5858 8 19.8787 7.56066 19.4393Z"
                                                    fill="#828282"
                                                />
                                                <path
                                                    d="M16 19V22H22V19C22 17.5858 22 16.8787 21.5607 16.4393C21.1213 16 20.4142 16 19 16C17.5858 16 16.8787 16 16.4393 16.4393C16 16.8787 16 17.5858 16 19Z"
                                                    fill="#828282"
                                                />
                                            </g>
                                        </svg>
                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#cbd5e1] dark:group-hover:text-white-dark">{t('RESULTS')}</span>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="menu nav-item">
                                <NavLink to={AppRoutes.UPCOMING_EVENTS} className="group">
                                    <div className="flex items-center">
                                        <svg className="shrink-0 group-hover:!text-primary" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.94028 2C7.35614 2 7.69326 2.32421 7.69326 2.72414V4.18487C8.36117 4.17241 9.10983 4.17241 9.95219 4.17241H13.9681C14.8104 4.17241 15.5591 4.17241 16.227 4.18487V2.72414C16.227 2.32421 16.5641 2 16.98 2C17.3958 2 17.733 2.32421 17.733 2.72414V4.24894C19.178 4.36022 20.1267 4.63333 20.8236 5.30359C21.5206 5.97385 21.8046 6.88616 21.9203 8.27586L22 9H2.92456H2V8.27586C2.11571 6.88616 2.3997 5.97385 3.09665 5.30359C3.79361 4.63333 4.74226 4.36022 6.1873 4.24894V2.72414C6.1873 2.32421 6.52442 2 6.94028 2Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                opacity="0.5"
                                                d="M21.9995 14.0001V12.0001C21.9995 11.161 21.9963 9.66527 21.9834 9H2.00917C1.99626 9.66527 1.99953 11.161 1.99953 12.0001V14.0001C1.99953 17.7713 1.99953 19.6569 3.1711 20.8285C4.34267 22.0001 6.22829 22.0001 9.99953 22.0001H13.9995C17.7708 22.0001 19.6564 22.0001 20.828 20.8285C21.9995 19.6569 21.9995 17.7713 21.9995 14.0001Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#cbd5e1] dark:group-hover:text-white-dark">{t('EVENTS')}</span>
                                    </div>
                                </NavLink>
                            </li>
                            {(isClub || isManager || isAdmin) && (
                                <>
                                    <li className="menu nav-item">
                                        <NavLink to={AppRoutes.CREATE_EVENT} className="group">
                                            <div className="flex items-center">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6.96006 2C7.37758 2 7.71605 2.30996 7.71605 2.69231V4.08883C8.38663 4.07692 9.13829 4.07692 9.98402 4.07692H14.016C14.8617 4.07692 15.6134 4.07692 16.284 4.08883V2.69231C16.284 2.30996 16.6224 2 17.0399 2C17.4575 2 17.7959 2.30996 17.7959 2.69231V4.15008C19.2468 4.25647 20.1992 4.51758 20.899 5.15838C21.5987 5.79917 21.8838 6.67139 22 8V9H2V8C2.11618 6.67139 2.4013 5.79917 3.10104 5.15838C3.80079 4.51758 4.75323 4.25647 6.20406 4.15008V2.69231C6.20406 2.30996 6.54253 2 6.96006 2Z"
                                                        fill="#828282"
                                                    />
                                                    <path
                                                        opacity="0.5"
                                                        d="M22 14V12C22 11.161 21.9873 9.66527 21.9744 9H2.00586C1.99296 9.66527 2.00564 11.161 2.00564 12V14C2.00564 17.7712 2.00564 19.6569 3.17688 20.8284C4.34813 22 6.23321 22 10.0034 22H14.0023C17.7724 22 19.6575 22 20.8288 20.8284C22 19.6569 22 17.7712 22 14Z"
                                                        fill="#828282"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M16 13.25C16.4142 13.25 16.75 13.5858 16.75 14V15.25L18 15.25C18.4142 15.25 18.75 15.5858 18.75 16C18.75 16.4142 18.4142 16.75 18 16.75H16.75L16.75 18C16.75 18.4142 16.4142 18.75 16 18.75C15.5858 18.75 15.25 18.4142 15.25 18L15.25 16.75L14 16.75C13.5858 16.75 13.25 16.4142 13.25 16C13.25 15.5858 13.5858 15.25 14 15.25H15.25L15.25 14C15.25 13.5858 15.5858 13.25 16 13.25Z"
                                                        fill="#828282"
                                                    />
                                                </svg>
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#cbd5e1] dark:group-hover:text-white-dark">{t('CREATE_EVENT')}</span>
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li className="menu nav-item">
                                        <button type="button" className={`${currentMenu === 'event' ? 'active' : ''} nav-link group w-full`} onClick={() => toggleMenu('event')}>
                                            <div className="flex items-center">
                                                <svg className="shrink-0 group-hover:!text-primary" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6.94028 2C7.35614 2 7.69326 2.32421 7.69326 2.72414V4.18487C8.36117 4.17241 9.10983 4.17241 9.95219 4.17241H13.9681C14.8104 4.17241 15.5591 4.17241 16.227 4.18487V2.72414C16.227 2.32421 16.5641 2 16.98 2C17.3958 2 17.733 2.32421 17.733 2.72414V4.24894C19.178 4.36022 20.1267 4.63333 20.8236 5.30359C21.5206 5.97385 21.8046 6.88616 21.9203 8.27586L22 9H2.92456H2V8.27586C2.11571 6.88616 2.3997 5.97385 3.09665 5.30359C3.79361 4.63333 4.74226 4.36022 6.1873 4.24894V2.72414C6.1873 2.32421 6.52442 2 6.94028 2Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        opacity="0.5"
                                                        d="M21.9995 14.0001V12.0001C21.9995 11.161 21.9963 9.66527 21.9834 9H2.00917C1.99626 9.66527 1.99953 11.161 1.99953 12.0001V14.0001C1.99953 17.7713 1.99953 19.6569 3.1711 20.8285C4.34267 22.0001 6.22829 22.0001 9.99953 22.0001H13.9995C17.7708 22.0001 19.6564 22.0001 20.828 20.8285C21.9995 19.6569 21.9995 17.7713 21.9995 14.0001Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('MANAGE_EVENT')}</span>
                                            </div>

                                            <div className={currentMenu !== 'event' ? 'rtl:rotate-90 -rotate-90' : ''}>
                                                <IconCaretDown />
                                            </div>
                                        </button>

                                        <AnimateHeight duration={300} height={currentMenu === 'event' ? 'auto' : 0}>
                                            <div className="p-1 mt-2 flex flex-wrap">
                                                Selected: <b className="ms-1">{selectedEvent.name}</b>{' '}
                                                <div className="flex gap-3">
                                                    <EditEvent event={selectedEvent} fetchEvents={fetchEvents!} inline />
                                                    <Tippy content={t('CHANGE_EVENT')} theme="info">
                                                        <button onClick={() => setEventModal(true)}>
                                                            <IconCalendar />
                                                        </button>
                                                    </Tippy>
                                                </div>
                                            </div>
                                            <ul className="sub-menu">
                                                {(selectedEvent.featScores || selectedEvent.featScores === undefined) && (
                                                    <li>
                                                        <NavLink to={AppRoutes.ENTER_SCORE}>{t('ENTER_SCORES')}</NavLink>
                                                    </li>
                                                )}
                                                {(selectedEvent.featMatches || selectedEvent.featMatches === undefined) && (
                                                    <li>
                                                        <NavLink to={AppRoutes.MATCHES}>{t('MATCHES')}</NavLink>
                                                    </li>
                                                )}
                                                {(selectedEvent.featParti || selectedEvent.featParti === undefined) && (
                                                    <>
                                                        <li>
                                                            <NavLink to={AppRoutes.ADD_PARTICIPANT}>{t('ADD_PARTICIPANT')}</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={AppRoutes.LIST_PARTICIPANTS}>{t('LIST_PARTICIPANTS')}</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={AppRoutes.REQUESTS}>{t('PENDING_REQUESTS')}</NavLink>
                                                        </li>
                                                        {selectedEvent.waitlist && <li>
                                                            <NavLink to={AppRoutes.WAITLIST}>{t('WAITLIST')}</NavLink>
                                                        </li>}
                                                    </>
                                                )}
                                                {(selectedEvent.featScores || selectedEvent.featScores === undefined) && (
                                                    <>
                                                        <li>
                                                            <NavLink to={AppRoutes.CREATE_TEAM}>{t('CREATE_TEAM')}</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={AppRoutes.LIST_TEAMS}>{t('LIST_TEAMS')}</NavLink>
                                                        </li>
                                                    </>
                                                )}
                                                {(selectedEvent.featParti || selectedEvent.featParti === undefined) && (
                                                    <>
                                                        <li>
                                                            <NavLink to={AppRoutes.GROUPS}>{t('GROUPS')}</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={AppRoutes.CATEGORIES}>{t('CATEGORIES')}</NavLink>
                                                        </li>
                                                    </>
                                                )}
                                                {(selectedEvent.featScores || selectedEvent.featScores === undefined) && (
                                                    <li>
                                                        <NavLink to={AppRoutes.AGGS}>{t('AGGS')}</NavLink>
                                                    </li>
                                                )}
                                                {(selectedEvent.featParti || selectedEvent.featParti === undefined) && (selectedEvent.featMatches || selectedEvent.featMatches === undefined) && (
                                                    <li>
                                                        <NavLink to={AppRoutes.SQUADDING}>{t('SQUADDING')}</NavLink>
                                                    </li>
                                                )}
                                                <li>
                                                    <NavLink className="text-black" to={AppRoutes.BUILD_FORM}>{t('CUSTOM_FORM')}</NavLink>
                                                </li>
                                            </ul>
                                        </AnimateHeight>
                                    </li>
                                </>
                            )}
                            {(isClub || isManager || isAdmin) && (
                                <>
                                    <li className="menu nav-item">
                                        <button type="button" className={`${currentMenu === 'club' ? 'active' : ''} nav-link group w-full`} onClick={() => toggleMenu('club')}>
                                            <div className="flex items-center">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        opacity="0.6"
                                                        d="M19.7165 20.3624C21.143 19.5846 22 18.5873 22 17.5C22 16.3475 21.0372 15.2961 19.4537 14.5C17.6226 13.5794 14.9617 13 12 13C9.03833 13 6.37738 13.5794 4.54631 14.5C2.96285 15.2961 2 16.3475 2 17.5C2 18.6525 2.96285 19.7039 4.54631 20.5C6.37738 21.4206 9.03833 22 12 22C15.1066 22 17.8823 21.3625 19.7165 20.3624Z"
                                                        fill="#828282"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M9.25 4C9.25 2.48122 10.4812 1.25 12 1.25C13.5188 1.25 14.75 2.48122 14.75 4C14.75 5.51878 13.5188 6.75 12 6.75C10.4812 6.75 9.25 5.51878 9.25 4Z"
                                                        fill="#828282"
                                                    />
                                                    <path
                                                        d="M8.22309 11.5741L6.04779 10.849C5.42206 10.6404 5 10.0548 5 9.39526C5 8.41969 5.89953 7.69249 6.85345 7.89691L8.75102 8.30353C8.85654 8.32614 8.9093 8.33744 8.96161 8.34826C10.966 8.76286 13.034 8.76286 15.0384 8.34826C15.0907 8.33744 15.1435 8.32614 15.249 8.30353L17.1465 7.8969C18.1005 7.69249 19 8.41969 19 9.39526C19 10.0548 18.5779 10.6404 17.9522 10.849L15.7769 11.5741C15.514 11.6617 15.3826 11.7055 15.2837 11.7666C14.9471 11.9743 14.7646 12.361 14.8182 12.753C14.834 12.8681 14.8837 12.9974 14.9832 13.256L16.23 16.4977C16.6011 17.4626 15.8888 18.4997 14.8549 18.4997C14.3263 18.4997 13.8381 18.2165 13.5758 17.7574L12 14.9997L10.4242 17.7574C10.1619 18.2165 9.67373 18.4997 9.14506 18.4997C8.11118 18.4997 7.39889 17.4626 7.77003 16.4977L9.01682 13.256C9.11629 12.9974 9.16603 12.8681 9.18177 12.753C9.23536 12.361 9.05287 11.9743 8.71625 11.7666C8.61741 11.7055 8.48597 11.6617 8.22309 11.5741Z"
                                                        fill="#828282"
                                                    />
                                                </svg>
                                                <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('MANAGE_CLUB')}</span>
                                            </div>

                                            <div className={currentMenu !== 'club' ? 'rtl:rotate-90 -rotate-90' : ''}>
                                                <IconCaretDown />
                                            </div>
                                        </button>

                                        <AnimateHeight duration={300} height={currentMenu === 'club' ? 'auto' : 0}>
                                            <>
                                                <div className="p-1 mt-2">
                                                    Selected: <b>{selectedClub.clubName}</b>
                                                </div>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <NavLink to={AppRoutes.CLUB_EVENTS}>{t('CLUB_EVENTS')}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={AppRoutes.CLUB_CALENDAR}>{t('CLUB_CALENDAR')}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={AppRoutes.YOUR_CLUBS}>{t('YOUR_CLUBS')}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={AppRoutes.RECORDS}>{t('RECORDS')}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={AppRoutes.PAYMENT_GATEWAYS}>{t('PAYMENT_GATEWAYS')}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={AppRoutes.CLUB_DIVISIONS}>{t('CLUB_DIVISIONS')}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={AppRoutes.PAST_EVENTS}>{t('PAST_EVENTS')}</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={AppRoutes.MORE_OPTIONS}>{t('MORE_OPTIONS')}</NavLink>
                                                    </li>
                                                </ul>
                                            </>
                                        </AnimateHeight>
                                    </li>
                                </>
                            )}
                            {(isClub || isManager || isAthlete || isAdmin) && (
                                <li className="menu nav-item">
                                    <NavLink to={AppRoutes.CREATE_CLUB} className="group">
                                        <div className="flex items-center">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z" fill="currentColor" />
                                                <path d="M18 16.5C18 18.433 15.3137 20 12 20C8.68629 20 6 18.433 6 16.5C6 14.567 8.68629 13 12 13C15.3137 13 18 14.567 18 16.5Z" fill="currentColor" />
                                                <path
                                                    d="M7.12205 5C7.29951 5 7.47276 5.01741 7.64005 5.05056C7.23249 5.77446 7 6.61008 7 7.5C7 8.36825 7.22131 9.18482 7.61059 9.89636C7.45245 9.92583 7.28912 9.94126 7.12205 9.94126C5.70763 9.94126 4.56102 8.83512 4.56102 7.47063C4.56102 6.10614 5.70763 5 7.12205 5Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M5.44734 18.986C4.87942 18.3071 4.5 17.474 4.5 16.5C4.5 15.5558 4.85657 14.744 5.39578 14.0767C3.4911 14.2245 2 15.2662 2 16.5294C2 17.8044 3.5173 18.8538 5.44734 18.986Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M16.9999 7.5C16.9999 8.36825 16.7786 9.18482 16.3893 9.89636C16.5475 9.92583 16.7108 9.94126 16.8779 9.94126C18.2923 9.94126 19.4389 8.83512 19.4389 7.47063C19.4389 6.10614 18.2923 5 16.8779 5C16.7004 5 16.5272 5.01741 16.3599 5.05056C16.7674 5.77446 16.9999 6.61008 16.9999 7.5Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M18.5526 18.986C20.4826 18.8538 21.9999 17.8044 21.9999 16.5294C21.9999 15.2662 20.5088 14.2245 18.6041 14.0767C19.1433 14.744 19.4999 15.5558 19.4999 16.5C19.4999 17.474 19.1205 18.3071 18.5526 18.986Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#cbd5e1] dark:group-hover:text-white-dark">{t('CREATE_CLUB')}</span>
                                        </div>
                                    </NavLink>
                                </li>
                            )}
                            <h2 className="py-3 px-7 flex items-center uppercase font-extrabold bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08] -mx-4 mb-1">
                                <span>{t('SUPPORT')}</span>
                            </h2>

                            <li className="menu nav-item">
                                <NavLink to="https://docs.targetscore.com/" target='_blank' className="group">
                                    <div className="flex items-center">
                                        <svg className="shrink-0 group-hover:!text-primary" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M4 4.69434V18.6943C4 20.3512 5.34315 21.6943 7 21.6943H17C18.6569 21.6943 20 20.3512 20 18.6943V8.69434C20 7.03748 18.6569 5.69434 17 5.69434H5C4.44772 5.69434 4 5.24662 4 4.69434ZM7.25 11.6943C7.25 11.2801 7.58579 10.9443 8 10.9443H16C16.4142 10.9443 16.75 11.2801 16.75 11.6943C16.75 12.1085 16.4142 12.4443 16 12.4443H8C7.58579 12.4443 7.25 12.1085 7.25 11.6943ZM7.25 15.1943C7.25 14.7801 7.58579 14.4443 8 14.4443H13.5C13.9142 14.4443 14.25 14.7801 14.25 15.1943C14.25 15.6085 13.9142 15.9443 13.5 15.9443H8C7.58579 15.9443 7.25 15.6085 7.25 15.1943Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                opacity="0.5"
                                                d="M18 4.00038V5.86504C17.6872 5.75449 17.3506 5.69434 17 5.69434H5C4.44772 5.69434 4 5.24662 4 4.69434V4.62329C4 4.09027 4.39193 3.63837 4.91959 3.56299L15.7172 2.02048C16.922 1.84835 18 2.78328 18 4.00038Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#cbd5e1] dark:group-hover:text-white-dark">{t('DOCUMENTATION')}</span>
                                    </div>
                                </NavLink>
                            </li>

                            <li className="menu nav-item">
                                <NavLink to="https://docs.targetscore.com/docs/contact" target="_blank" className="nav-link group">
                                    <div className="flex items-center">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                opacity="0.5"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                                                fill="#828282"
                                            />
                                            <path
                                                d="M5.47875 19.5818L9.75149 15.309C9.3348 15.0254 8.97447 14.6651 8.69079 14.2484L4.41807 18.5211C4.74471 18.9005 5.09934 19.2551 5.47875 19.5818Z"
                                                fill="#828282"
                                            />
                                            <path
                                                d="M4.41797 5.47912L8.6907 9.75185C8.97436 9.33516 9.33468 8.97485 9.75136 8.69119L5.47863 4.41846C5.09922 4.74509 4.7446 5.09971 4.41797 5.47912Z"
                                                fill="#828282"
                                            />
                                            <path
                                                d="M14.2479 8.69128L18.5206 4.41856C18.9 4.7452 19.2547 5.09982 19.5813 5.47924L15.3085 9.75198C15.0249 9.33529 14.6646 8.97496 14.2479 8.69128Z"
                                                fill="#828282"
                                            />
                                            <path
                                                d="M19.5812 18.521L15.3084 14.2483C15.0248 14.665 14.6645 15.0253 14.2478 15.3089L18.5205 19.5817C18.8999 19.255 19.2545 18.9004 19.5812 18.521Z"
                                                fill="#828282"
                                            />
                                        </svg>
                                        <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#cbd5e1] dark:group-hover:text-white-dark">{t('CONTACT_US')}</span>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav>
            <Transition appear show={eventModal} as={Fragment}>
                <Dialog as="div" open={eventModal} onClose={() => setEventModal(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel as="div" className="panel border-0 p-0 rounded-lg w-full max-w-lg my-8 text-black dark:text-white-dark">
                                    <SelectedEvent />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default Sidebar;
