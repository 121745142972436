import { forwardRef } from 'react';

export type TextProps = {
    id: string;
    name: string;
    label: string;
    col: string;
    fullWidth?: boolean;
};

type Ref = HTMLDivElement;

export const TextLine = forwardRef<Ref, TextProps>((props, ref) => {
    const { id, label = '', fullWidth = false, ...restProps } = props;

    return id.includes('rich') ? <div className="richText" dangerouslySetInnerHTML={{ __html: label }} /> : <div>{label}</div>;
});
